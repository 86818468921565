import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    redirect: '/carrier-application'
  },
  {
    path: '/carrier-application',
    name: 'carrier-application',
    meta: {
      layout: 'App'
    },
    component: () => import('../views/carrier-application/CarrierApplicationView.vue')
  },
  {
    path: '/search-loads',
    name: 'search-loads',
    meta: {
      layout: 'App'
    },
    component: () => import('../views/search-loads/SearchLoadsView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
