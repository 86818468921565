<template>
  <footer :class="{'footer-mobile': isMobile}">
    <div class="container">
      <div v-if="!isMobile" class="row row-cols-1 row-cols-sm-2 row-cols-md-5 py-5">
        <div class="col mb-3">
          <router-link to="/" class="navbar-brand">
            <img src="@/assets/images/logo.png" loading="lazy" class="footer-logo" alt="Pulltail Carrier Logo">
          </router-link>
          <p class="text-body-secondary m-0 py-4 text-nowrap">© 2024 Pulltail. All Rights Reserved.</p>
          <ul class="row list-unstyled">
            <li class="col-3">
              <img src="@/assets/images/american-express.png" loading="lazy" class="w-100" alt="AmericanExpress">
            </li>
            <li class="col-3">
              <img src="@/assets/images/visa.webp" loading="lazy" class="w-100" alt="Visa">
            </li>
            <li class="col-3">
              <img src="@/assets/images/master-card.webp" loading="lazy" class="w-100" alt="MasterCard">
            </li>
            <li class="col-3">
              <img src="@/assets/images/discover.png" loading="lazy" class="w-100" alt="Discover">
            </li>
          </ul>
        </div>
        <div class="col mb-3"></div>
        <div class="col mb-3">
          <h5 class="footer-link-title">Locations</h5>
          <ul class="nav flex-column">
            <li v-for="(link, index) in links.locations" :key="index" class="nav-item footer-link-item">
              <router-link :to="link.url" class="nav-link p-0 footer-link">{{ link.title }}</router-link>
            </li>
          </ul>
        </div>
        <div class="col mb-3">
          <h5 class="footer-link-title">Applications</h5>
          <ul class="nav flex-column">
            <li v-for="(link, index) in links.applications" :key="index" class="nav-item footer-link-item">
              <router-link :to="link.url" class="nav-link p-0 footer-link">{{ link.title }}</router-link>
            </li>
          </ul>
        </div>
        <div class="col mb-3">
          <h5 class="footer-link-title">Contact Info</h5>
          <ul class="nav flex-column">
            <li class="nav-item p-0 footer-link-item">
              <p class="footer-link">(513) 800-2711</p>
            </li>
            <li class="nav-item p-0">
              <p class="footer-link">rental@pulltail.com</p>
            </li>
          </ul>
        </div>
      </div>
      <template v-else>
        <div class="d-flex flex-column footer-mobile-contact-info">
          <h5 class="d-flex flex-column pb-3">
            <h5 class="pb-2 footer-mobile-contact-info-title">Contact Info</h5>
            <div class="footer-mobile-contact-info-title-line"></div>
          </h5>
          <div class="d-flex flex-row align-items-center justify-content-start pb-3">
            <font-awesome-icon icon="fa-solid fa-phone" class="p-3 footer-mobile-icon"/>
            <p class="m-0 footer-mobile-contact-info-text">(513) 800-2711</p>
          </div>
          <div class="d-flex flex-row align-items-center justify-content-start">
            <font-awesome-icon icon="fa-regular fa-envelope" class="p-3 footer-mobile-icon"/>
            <p class="m-0 footer-mobile-contact-info-text">rental@pulltail.com</p>
          </div>
        </div>
        <div class="row">
          <p
            class="col-sm-6 col-12 d-flex align-items-center justify-content-sm-start justify-content-center m-0 footer-mobile-rights-text">
            © 2024
            Pulltail. All Rights Reserved.</p>
          <div class="col-sm-6 col-12 d-flex align-items-center justify-content-sm-end justify-content-center">
            <ul class="d-flex list-unstyled p-0 m-0">
              <li class="footer-mobile-cards-item-container">
                <div class="w-100 h-100 footer-mobile-cards-item">
                  <img src="@/assets/images/american-express.png" loading="lazy" class="w-100" alt="AmericanExpress">
                </div>
              </li>
              <li class="footer-mobile-cards-item-container">
                <div class="w-100 h-100 footer-mobile-cards-item">
                  <img src="@/assets/images/visa.webp" loading="lazy" class="w-100" alt="Visa">
                </div>
              </li>
              <li class="footer-mobile-cards-item-container">
                <div class="w-100 h-100 footer-mobile-cards-item">
                  <img src="@/assets/images/master-card.webp" loading="lazy" class="w-100" alt="MasterCard">
                </div>
              </li>
              <li class="footer-mobile-cards-item-container">
                <div class="w-100 h-100 footer-mobile-cards-item">
                  <img src="@/assets/images/discover.png" loading="lazy" class="w-100" alt="Discover">
                </div>
              </li>
            </ul>
          </div>
        </div>
      </template>
    </div>
  </footer>
</template>

<script>
import { ref, onMounted } from 'vue'

export default {
  name: 'FooterComponent',
  setup () {
    const isMobile = ref(false)

    onMounted(() => {
      if (window.matchMedia('(max-width: 992px)').matches) {
        isMobile.value = true
      }

      window.addEventListener('resize', () => {
        if (window.matchMedia('(max-width: 992px)').matches) {
          isMobile.value = true
        } else {
          isMobile.value = false
        }
      })
    })

    return {
      isMobile,
      links: {
        locations: [
          {
            title: 'Sacramento, CA',
            url: '#'
          },
          {
            title: 'Dallas, TX',
            url: '#'
          },
          {
            title: 'Charlotte, NC',
            url: '#'
          },
          {
            title: 'Atlanta, GA',
            url: '#'
          },
          {
            title: 'Winston-Salem, NC',
            url: '#'
          },
          {
            title: 'Bakersfield, CA',
            url: '#'
          },
          {
            title: 'Phelan, CA',
            url: '#'
          }
        ],
        applications: [
          {
            title: 'Carrier',
            url: { name: 'carrier-application' }
          },
          {
            title: 'Rental',
            url: '#'
          }
        ]
      }
    }
  }
}
</script>

<style scoped>
.footer-logo {
  width: 18.438rem;
}

.footer-link-title {
  font-weight: 700;
  color: #000000;
  font-size: 1.313rem;
  line-height: 1.688rem;
  padding-bottom: 1.25rem;
}

.footer-link-item {
  padding-bottom: 0.625rem;
}

.footer-link {
  color: inherit;
  font-size: 1rem;
  font-weight: 400;
}

.footer-link:hover {
  color: inherit !important;
}

.footer-mobile {
  padding: 2.5rem 0;
  background-color: #201f1d;;
}

.footer-mobile-contact-info {
  padding-bottom: 4rem;
}

.footer-mobile-contact-info-title {
  color: #ffffff;
  font-size: 1.25rem;
  font-weight: 700;
}

.footer-mobile-contact-info-title-line {
  width: 27px;
  height: 4px;
  background-color: #fcb215;
}

.footer-mobile-icon {
  background-color: #ff9307;
  color: #ffffff;
  font-size: 1.25rem;
  border-radius: 4px;
  flex-shrink: 0;
}

.footer-mobile-contact-info-text {
  color: #ffffff;
  padding-left: 1.25rem;
  font-size: 1.125rem;
  font-weight: 500;
}

.footer-mobile-rights-text {
  color: #ffffff;
}

@media only screen and (max-width: 576px) {
  .footer-mobile-rights-text {
    padding-bottom: 1.25rem;
  }
}

.footer-mobile-cards-item-container {
  width: 2.875rem;
  height: 1.875rem;
  margin: 0 0.5rem;
  overflow: hidden;
}

.footer-mobile-cards-item {
  background-color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 0.313rem;
}
</style>
