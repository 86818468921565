import { createApp } from 'vue'
import App from './App.vue'

import router from './router'
import store from './store'
import bootstrap from 'bootstrap/dist/js/bootstrap.js'
import { vMaska } from 'maska/vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
// import all from '@fortawesome/free-brands-svg-icons'
import { faEnvelope, faImage } from '@fortawesome/free-regular-svg-icons'
import {
  faPhone,
  faFileCircleCheck,
  faTrash,
  faArrowRightArrowLeft,
  faEllipsisVertical,
  faCircleXmark,
  faCalendar,
  faMagnifyingGlass,
  faCheck,
  faCircleDot,
  faCircle,
  faCircleInfo,
  faArrowDown,
  faArrowUp,
  faCircleChevronDown,
  faSquareUpRight,
  faAngleDown,
  faRotateRight,
  faDollarSign
} from '@fortawesome/free-solid-svg-icons'
import rangeCalendar from 'vue3-range-calendar'
import 'vue3-range-calendar/dist/styles/index.css'

import { asset } from '@/util/helpers'

library.add(
  faPhone,
  faEnvelope,
  faImage,
  faFileCircleCheck,
  faTrash,
  faArrowRightArrowLeft,
  faEllipsisVertical,
  faCircleXmark,
  faCalendar,
  faMagnifyingGlass,
  faCheck,
  faCircleDot,
  faCircle,
  faCircleInfo,
  faArrowDown,
  faArrowUp,
  faCircleChevronDown,
  faSquareUpRight,
  faAngleDown,
  faRotateRight,
  faDollarSign
)

createApp(App)
  .use(store)
  .use(router)
  .use(bootstrap)
  .use(rangeCalendar)
  .directive('mask', vMaska)
  .component('font-awesome-icon', FontAwesomeIcon)
  .mixin({
    methods: {
      asset
    }
  })
  .mount('#app')
