/**
 * @param path
 * @returns {string}
 */
export const asset = (path) => `${window.location.origin}/${path}`

/**
 * @param obj
 * @param keys
 * Get JSON deep nested values by keys.
 * someJson = {
 *   nested1: {
 *     nested2: {
 *       value: 1
 *     }
 *   }
 * }
 * getJsonValueDeep(someJson, ['nested1', 'nested2', 'value']) -> get 1
 */
export const getJsonValueDeep = (obj, keys) => keys.reduce((xs, x) => xs?.[x] ?? null, obj)
