<template>
  <component :is="layout">
    <router-view/>
  </component>
</template>
<script>
import App from '@/layouts/App'

export default {
  components: {
    App
  },
  computed: {
    layout () {
      return this.$route.meta.layout
    }
  }
}
</script>
<style>
@import "~bootstrap/dist/css/bootstrap.css";
@import "assets/styles/style.css";
</style>
