<template>
  <div class="wrapper">
    <header-component />
    <main class="container content">
      <router-view/>
    </main>
    <footer-component />
  </div>
</template>
<script>
import HeaderComponent from '@/components/HeaderComponent'
import FooterComponent from '@/components/FooterComponent'

export default {
  name: 'App',
  components: {
    HeaderComponent,
    FooterComponent
  }
}
</script>
