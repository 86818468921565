<template>
  <nav class="navbar navbar-expand-lg sticky-top header">
    <div class="container">
      <router-link to="/" class="navbar-brand">
        <img src="@/assets/images/logo.png" loading="lazy" class="header-logo" alt="Pulltail Carrier Logo">
      </router-link>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse"
              aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarCollapse">
        <ul class="navbar-nav ms-auto me-5">
          <li v-for="(link, index) in links" :key="index" class="nav-item">
            <router-link :to="link.url" class="nav-link header-link">{{ link.title }}</router-link>
          </li>
        </ul>
        <div class="d-flex flex-column">
          <a href="tel:(513) 800-2711" class="header-contact-icon text-decoration-none">
            <font-awesome-icon icon="fa-solid fa-phone"/>
            <span class="header-contact-text">(513) 800-2711</span>
          </a>
          <a href="mailto:rental@pulltail.com" target="_blank" class="header-contact-icon text-decoration-none">
            <font-awesome-icon icon="fa-regular fa-envelope"/>
            <span class="header-contact-text">rental@pulltail.com</span>
          </a>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>

export default {
  name: 'HeaderComponent',
  data: () => ({
    links: [
      {
        title: 'Carrier Application',
        url: { name: 'carrier-application' }
      },
      {
        title: 'Search Loads',
        url: { name: 'search-loads' }
      }
    ]
  })
}
</script>

<style scoped>
.header {
  padding: 1.2rem 0;
  background-color: #ffffff;
}

.header-logo {
  width: 10.625rem;
}

.header-link {
  color: #2f2f2f !important;
  font-weight: 600 !important;
  transition: all .2s ease !important;
}

.header-link:hover {
  color: orange !important;
}

.header-contact-icon {
  color: #787878
}

.header-contact-text {
  padding-left: 0.5rem;
}
</style>
